import { render, staticRenderFns } from "./UserReport.vue?vue&type=template&id=16d969af&scoped=true&"
import script from "./UserReport.vue?vue&type=script&lang=js&"
export * from "./UserReport.vue?vue&type=script&lang=js&"
import style0 from "./UserReport.vue?vue&type=style&index=0&id=16d969af&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d969af",
  null
  
)

export default component.exports