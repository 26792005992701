<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title>접속 리포트</v-card-title>
      <v-row no-gutters class="px-5">
        <v-col cols="12" lg="2" sm="12" class="mr-3">
          <v-select
            class="select"
            v-model="type"
            :items="type_items"
            label="유형"
            item-text="name"
            item-value="id"
            hide-details
            @change="getData()"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="2" sm="12">
          <v-text-field
            v-model="page"
            label="접속 경로"
            @keyup.enter="getData()"
            hide-details
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="1" sm="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="startDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="시작 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              ref="startDate"
              @input="startMenu = false"
              :max="startMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <p class="text-center mx-auto px-5 mt-4">~</p>
        <v-col cols="12" lg="1" sm="12" class="mr-5">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="endDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="종료 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              ref="endDate"
              @input="endMenu = false"
              :min="startDate"
              :max="endMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-btn color="primary" @click="getData()" class="mx-auto mt-3"
          >검색</v-btn
        >
        <v-btn @click="refresh()" class="mx-auto ml-2 mt-3">초기화</v-btn>
      </v-row>
      <v-row>
        <v-col class="px-7">
          <v-progress-circular
            v-if="loading"
            :indeterminate="loading"
            class="progress_circular"
          ></v-progress-circular>
          <div
            v-if="chart"
            :style="`height:${chartHeight}px;`"
            class="chart_wrap"
          >
            <div ref="chart" style="height: 100%"></div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-5 mb-16">
      <v-col>
        <v-simple-table class="table">
          <template>
            <thead class="sticky_header">
              <tr>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  날짜
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  접속 수(전체)
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  유형
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  접속 경로
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  접속 수(개별)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tableItems" :key="i">
                <td
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                  :style="{ color: getWeekend(item.date) }"
                >
                  {{ item.date }}
                </td>
                <td style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                  {{ item.dateTotal.toLocaleString() }}
                </td>
                <td style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                  <p
                    v-for="(type, i) in item.types"
                    :key="i"
                    :style="{
                      height: getHeight(type.length),
                      lineHeight: getLineHeight(type.length),
                    }"
                  >
                    {{
                      type.type === "sdk"
                        ? "SDK"
                        : type.type === "search"
                        ? "포털사이트"
                        : type.type === "direct"
                        ? "다이렉트"
                        : "기타"
                    }}
                  </p>
                </td>

                <td style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                  <div
                    v-for="(type, i) in item.types"
                    :key="i"
                    class="table_inner_div"
                  >
                    <p v-for="(page, i) in type.pages" :key="i">
                      {{ page.page }}
                    </p>
                  </div>
                </td>
                <td>
                  <div
                    v-for="(type, i) in item.types"
                    :key="i"
                    class="table_inner_div"
                  >
                    <p v-for="(page, i) in type.pages" :key="i">
                      {{ page.cnt.toLocaleString() }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <ScrollTop />
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import ScrollTop from "@/components/common/ScrollTop.vue";

export default {
  name: "UserReport",
  components: {
    ScrollTop,
  },
  data() {
    return {
      type: "",
      type_items: [
        {
          id: "",
          name: "전체",
        },
        {
          id: "sdk",
          name: "SDK",
        },
        {
          id: "search",
          name: "포털사이트",
        },
        {
          id: "direct",
          name: "다이렉트",
        },
      ],
      page: "",

      today: moment().format("YYYY-MM-DD"),
      startDate: null,
      startMenu: false,
      endDate: null,
      endMenu: false,
      startMaxDate: moment(this.endDate).format("YYYY-MM-DD"),
      endMaxDate: moment(this.today).format("YYYY-MM-DD"),
      formatStartDate: null,
      formatEndDate: null,

      // 차트옵션 공통 값
      colorPalette: ["#599BFF", "#FE83B7", "#FFBC57", "#26C7AA"],
      legendData: [],

      chart: false,
      loading: false,
      chartHeight: 0,

      tableItems: [],
      sdk_total: [],
      search_total: [],
      direct_total: [],
    };
  },
  mounted() {
    this.initDate();
    this.getData();
  },
  methods: {
    initDate() {
      this.startDate = moment(this.today).subtract(1, "M").format("YYYY-MM-DD");
      this.endDate = this.today;
      this.formatStartDate = moment(this.startDate).format("YYYY-MM-DD");
      this.formatEndDate = moment(this.endDate).format("YYYY-MM-DD");
    },

    refresh() {
      this.initDate();
      this.type = "";
      this.page = "";
      this.getData();
    },

    getHeight(length) {
      let num;
      if (length > 1) {
        num = 28 * length;
        return `${num}px`;
      }
    },

    getLineHeight(length) {
      let num;
      if (length > 1) {
        num = 26.5 * length;
        return `${num}px`;
      }
    },

    getWeekend(date) {
      if (moment(date).day() === 6) {
        return "blue";
      } else if (moment(date).day() === 0) {
        return "red";
      } else {
        return "rgba(0,0,0,.87)";
      }
    },

    getWeek(date) {
      const weekOfMonth = (m) =>
        m.week() - moment(m).startOf("month").week() + 1;
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("MM월 ") + weekOfMonth(nowDate) + "주";
      return final;
    },

    getMonth(date) {
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("YYYY년 MM") + "월";
      return final;
    },

    resetChartField() {
      this.tableItems = [];
      this.date_total = [];
      this.sdk_total = [];
      this.search_total = [];
      this.direct_total = [];
    },

    async getData() {
      this.loading = true;
      this.resetChartField();

      let items = [];
      let sdk_total = [];
      let search_total = [];
      let direct_total = [];
      let params = {
        start_at: this.startDate,
        end_at: this.endDate,
      };
      if (this.type !== "") {
        params["type"] = this.type;
      }
      if (this.page !== "") {
        params["page"] = this.page;
      }
      await this.axios
        .get(`api/v1/stats/access/intro`, { params })
        .then((res) => {
          if (res.data.logs.length > 0) {
            items = res.data.logs.map((el) => {
              el.types.map((el2) => {
                el2.length = el2.pages.length;
                return el2;
              });
              if (this.type !== "") {
                let filtered = el.types.filter(
                  (item) => item.type == this.type
                );
                return { ...el, types: filtered };
              } else {
                return el;
              }
            });

            items.map((el) => {
              el.types.map((el2) => {
                if (el2.type === "sdk") {
                  sdk_total.push(el2.typeTotal);
                } else if (el2.type === "search") {
                  search_total.push(el2.typeTotal);
                } else if (el2.type === "direct") {
                  direct_total.push(el2.typeTotal);
                }
              });
            });

            this.chart = true;
            this.chartHeight = 350;
          }
          this.tableItems = items.reverse();
          this.sdk_total = sdk_total;
          this.search_total = search_total;
          this.direct_total = direct_total;
        })
        .catch(() => {
          this.chart = false;
          this.loading = false;
        });

      if (this.chart) {
        this.getChart();
      }
      this.loading = false;
    },

    async getChart() {
      this.legendData = ["전체 접속 수", "SDK", "포털사이트", "다이렉트"];
      if (this.type !== "") {
        this.type_items.map((el) => {
          let searchType;
          if (this.type === el.id) {
            searchType = el.name;
            this.legendData = this.legendData.filter((el2) => {
              return el2 === searchType;
            });
          }
        });
      } else {
        this.legendData = ["전체 접속 수", "SDK", "포털사이트", "다이렉트"];
      }

      this.$nextTick(() => {
        const chartDom = this.$refs.chart;
        let myChart = echarts.init(chartDom);

        myChart.setOption({
          color: this.colorPalette,
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              let item = "";
              params.forEach((param) => {
                item += `<div style="display:flex;justify-content:space-between;text-align:left"><span style="margin-right:10px">${
                  param.marker
                }${
                  param.seriesName
                }</span><span style="font-weight:600">${param.value.toLocaleString()}</span></div>`;
              });

              let intervalValue = params[0].name;
              if (this.interval === "weekly") {
                return `<p style="margin-bottom: 10px;text-align:left">${this.getWeek(
                  intervalValue
                )}</p> ${item}`;
              } else if (this.interval === "monthly") {
                return `<p style="margin-bottom: 10px;text-align:left">${this.getMonth(
                  intervalValue
                )}</p> ${item}`;
              } else {
                return `<p style="margin-bottom: 10px;text-align:left">${intervalValue}</p> ${item}`;
              }
            },
          },
          legend: {
            data: this.legendData,
            orient: "horizontal",
            top: "bottom",
          },
          grid: {
            left: "0%",
            top: "5%",
            right: "20px",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: this.tableItems
              .map((el) => {
                return el.date.substr(5, 9);
              })
              .reverse(),
            axisTick: { show: false },
            axisLabel: {
              textStyle: {
                color: (value) => {
                  if (moment(value).day() === 6) {
                    return "blue";
                  } else if (moment(value).day() === 0) {
                    return "red";
                  } else {
                    return "#000";
                  }
                },
              },
            },
          },
          yAxis: [
            {
              type: "value",
              alignTicks: true,
              axisLine: {
                show: true,
              },
            },
          ],
          series: [
            {
              name: "전체 접속 수",
              type: "line",
              data: this.tableItems
                .map((el) => {
                  return el.dateTotal;
                })
                .reverse(),
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "SDK",
              type: "line",
              data: this.sdk_total,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "포털사이트",
              type: "line",
              data: this.search_total,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "다이렉트",
              type: "line",
              data: this.direct_total,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
          ],
        });
        window.addEventListener("resize", myChart.resize);
      });
    },
  },
};
</script>
<style scoped>
.table tbody tr td p:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table tbody tr td .table_inner_div:not(:last-of-type) p {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.v-application p {
  margin: 0;
  padding: 3px 0;
}
.v-application th,
.v-application td {
  white-space: nowrap;
}
.v-application td {
  padding: 0 !important;
}
.min {
  color: red;
  font-weight: 600;
}
.max {
  color: blue;
  font-weight: 600;
}
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}
@media all and (max-width: 1023px) {
  .v-data-table >>> .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
